<template>
  <Split class="VodPlayer" direction="column" :padding="0" :sideOffset="0.7">
    <template #first>
      <div :id="playerId" class="playerContainer" ref="playerRef"></div>
    </template>
    <template #second>
      <VodFooter>
        <template #timeline>
          <VodTimeLine
            :startPos="timelineRange[0]"
            :endPos="timelineRange[1]"
            :channels="channels"
            :fileList="videoFileList"
            @playClick="onPlayClick"
          ></VodTimeLine>
        </template>
        <template #file>
          <VodFileTable
            :fileList="videoFileList"
            @playClick="onPlayClick"
            :ftpDown="onFtpDown"
            @fptCancel="onFtpCancel"
          ></VodFileTable>
        </template>
      </VodFooter>
    </template>
  </Split>
</template>

<script>
import { throttle } from 'lodash';
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import { appConfig } from '@/config/appConfig';
import Split from '@/components/Split';

import ByskPlayer from '@/components/ByskPlayer';
// import ByskPlayer from '@/libs/byskplayer';

import { randomNumber } from '@/utils/random';
import { sleep } from '@/utils/sleep';

import VodFooter from './VodFooter.vue';
import VodTimeLine from '@/components/VodTimeLine';
import VodFileTable from './VodFileTable.vue';

// 默认通道号
const defaultChannels = [1, 2, 3, 4];

// 一小时`3600000`毫秒
const oneHourMs = 1000 * 60 * 60;
// 一天`86400000`毫秒
const oneDayMs = oneHourMs * 24;
const todayStart = dayjs(dayjs().format('YYYY-MM-DD 00:00:00.000'));
// 今日开始时间 毫秒
const todayStartMs = todayStart.valueOf();
// 今日结束时间 毫秒
const todayEndMs = todayStartMs + oneDayMs - 1;

export default {
  name: 'VodPlayer',
  components: {
    Split,
    VodFooter,
    VodTimeLine,
    VodFileTable,
  },
  props: {
    videoCloseTime: {
      type: Number,
      default: 5
    }
  },
  data() {
    this.playerObj = null;
    /** <channel,{videoPos,vid,device,protocolType}> */
    this.channelMap = new Map();

    return {
      playerId: `vod-${randomNumber()}`,
      plateObj: null,
      // 视频文件列表
      videoFileList: [],
      // 通道号
      channels: defaultChannels,
      // 时间轴时间范围 [开始毫秒, 结束毫秒]
      timelineRange: [todayStartMs, todayEndMs],
    };
  },
  computed: {
    ...mapState(['userInfo', 'appToken',"searchLoading"]),
  },
  watch: {
    videoCloseTime(val) {
      const { plateObj } = this;
      if (plateObj) {
        plateObj.autoCloseTime = val;
      };
    },
  },
  beforeMount() {
    this.onPlayStart = this.onPlayStart.bind(this);
    this.onPlayEnd = this.onPlayEnd.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.onPlayerMessage = this.onPlayerMessage.bind(this);
  },
  mounted() {

    this.initPlayer();
  },
  beforeDestroy() {
    const { playerObj } = this;
    if (playerObj) {
      playerObj.destroy();
    }
    this.playerObj = null;
  },
  methods: {

    getChannelMap(channel) {
      return this.channelMap.get(channel);
    },
    setChannelMap(channel, item) {
      let videoPos = 0;
      if (this.channelMap.has(channel)) {
        videoPos = this.channelMap.get(channel).videoPos;
      }
      this.channelMap.set(channel, {
        videoPos,
        ...(item || {})
      });
    },
    async onPlayClick(params) {
      if ((await this.stopAll(200)).length > 0) {
        this.emitPlayEnd();
      }

      const { SpecialSign, terminalType, protocolType, plate, vehicleId, groupId } = this.plateObj;

      const isGB1078 = terminalType === 'GB-1078';

      const channelParams = {
        ...params,
        SpecialSign,
        protocolType,
        plate,
        vehicleId,
        groupId,
      };

      if (isGB1078) {
        // 0：音视频
        channelParams.datatype = 0;
      } else {
        // 4：音视频和定位数据一起上传
        channelParams.datatype = 4;
      }
      // GB-1078默认不打开声音，单独一条链路开启声音
      const hasAudio = !isGB1078;

      if (await this.playChannel(channelParams, hasAudio)) {
        this.emitPlayStart();
      }

    },
    /** 判断指定通道是否播放 */
    isChannelPlaying(channel) {
      if (this.channelMap.has(channel)) {
        return !!this.getChannelMap(channel).vid;
      }
      return false;
    },
    /** 获取所有正在播放的通道 Array<channel,Array<item>> */
    getChannelsPlaying() {
      return [...this.channelMap.entries()].filter(([channel]) =>
        this.isChannelPlaying(channel)
      );
    },
    /** 播放指定通道 */
    playChannel(params, hasAudio = true) {
      return new Promise(resolve => {
        const item = this.getChannelMap(params.channel);
        // 播放器ID videoId
        const vid = this.playerObj.allocate(1, item.videoPos).ids[0];

        // 设置播放通道信息
        this.setChannelMap(params.channel, {
          vid,
          device: params.device,
          protocolType: params.protocolType
        });

        this.playerObj.record.open(
          vid,
          params,
          res => {
            if (![1, 2].includes(res.status)) {
              // 请求失败重置
              this.setChannelMap(channel);
            }

            if (![2].includes(res.status)) {
              resolve([1].includes(res.status));
            }
          },
          { hasAudio }
        );
      });
    },
    /** 所有通道停止播放 */
    stopAll(timeout = 0) {
      return new Promise(resolve => {
        const list = this.getChannelsPlaying();

        list.forEach(([channel, item]) => {
          this.setChannelMap(channel);
          this.playerObj.record.close(item.vid, {
            device: item.device,
            protocolType: item.protocolType,
            channel,
            replayBtnHide: true
          });
        });

        if (timeout > 0) {
          return sleep(timeout).then(() => resolve(list));
        }
        resolve(list);
      });
    },
    /** 停止指定通道播放 */
    stopChannel(channel, timeout) {
      return new Promise(resolve => {
        const item = this.getChannelMap(channel);
        if (!(item && item.vid)) {
          return resolve();
        }

        this.playerObj.record.close(item.vid, {
          device: item.device,
          protocolType: item.protocolType,
          channel
        });
        // 重置
        this.setChannelMap(channel);
        if (timeout > 0) {
          return delay(resolve, timeout);
        }
        resolve();
      });
    },

    async query(data) {
      // SpecialSign: 0
      // beginTime: "2021-11-29 00:00:00"
      // channels: (4) [1, 2, 3, 4]
      // device: "40268292051"
      // endTime: "2021-11-29 23:59:59"
      // groupId: 3971
      // groupName: undefined
      // plate: "40268292051"
      // terminalType: "GF-1078"
      // vehicleId: 9042452

      this.videoFileList = [];
      this.channels = defaultChannels;

      try {

        if ((await this.stopAll()).length > 0) {
          this.emitPlayEnd();
        }

        const { channels } = data;
        // 协议类型 1: GF-1078, GB-1078; 2: GA系列
        // const protocolType = ['GF-1078', 'GB-1078'].includes(data.terminalType) ? 1 : 2;
      const protocolType = data.terminalType.indexOf("GA-") < 0
        ? 1
        : 2;

        const result = await this.queryFiles({
          storgetype: data.storgetype,
          alarmSign: data.alarmSign,
          begintime: data.beginTime,
          endtime: data.endTime,
          device: data.device,
          channel: 0,
          protocolType,
        });

        if (result.status !== 1) {
          return this.$message.warning(result.info);
        }

        if (this.searchLoading && this.searchLoading== true) {
          this.$message.info('查询成功');
          var fileList = result
          .data
          .filter(p => channels.includes(+p.channel))
          .sort((a, b) => a.channel - b.channel);
        }
        this.channels = channels;
        this.videoFileList = fileList;
        this.timelineRange = [data.beginTime * 1000, data.endTime * 1000];

        this.plateObj = {
          channels,
          device: data.device,
          plate: data.plate,
          vehicleId: data.vehicleId,
          groupName: data.groupName,
          groupId: data.groupId,
          protocolType,
          terminalType: data.terminalType,
          SpecialSign: data.SpecialSign,
        };

        channels
          .forEach((channel, index) => {
            this.setChannelMap(channel, { videoPos: 0/** 多通道 index */ });
          });

      } catch (err) {
        console.error(err);
      }

    },

    queryFiles(params) {
      params.uuid = `v-${randomNumber()}`;
      return new Promise((resolve, inject) => {
        const { playerObj } = this;
        playerObj.record.query(params, (res) => {
          if (res.uuid !== params.uuid) {
            return resolve(null);
          }
          resolve({
            channel: res.channel,
            data: res.status == 1 ? res.recordfiles : [],
            uuid: res.uuid,
            info: res.info,
            status: res.status
          });
        });
      });
    },
    async initPlayer() {
      await this.$nextTick();

      const { playerId, userInfo, appToken, videoCloseTime } = this;
      const baseURL = this.$route.name == 'foreignBigScreenDisplayVideoPolling' || this.$route.name == 'foreignVideoPolling' ? 'wss://112.74.168.234:8899' : appConfig.baseMediaUrl;

      const player = new ByskPlayer({
        id: playerId,
        isRecord: true,
        baseURL,
        userId: userInfo.name,
        userkey: appToken,
        playstart: this.onPlayStart,
        playend: this.onPlayEnd,
        register: this.onRegister,
        message: this.onPlayerMessage,
      });

      this.playerObj = player;

      await this.$nextTick();

      player.setPlayerNum(1);

      if (videoCloseTime) {
        player.autoCloseTime = videoCloseTime;
      }
    },

    // ftp上传至服务器
    ftpDownloadToServer(data) {
      return new Promise((resolve) => {
        this.playerObj
          .ftp
          .downloadToServer(data, res => {
            resolve(res);
          });
      });
    },
    // ftp上传暂停
    ftpPause(data) {
      return new Promise((resolve) => {
        this.playerObj.ftp
          .pause(data, res => {
            resolve(res);
          });
      });
    },
    // ftp上传继续
    ftpContinue(data) {
      return new Promise((resolve) => {
        this.playerObj.ftp.continue(data, res => {
          resolve(res);
        });
      });
    },
    // ftp上传取消
    ftpCancel(data) {
      return new Promise((resolve) => {
        this.playerObj
          .ftp.cancel(data, res => {
            resolve(res);
          });
      });
    },

    onPlayStart(obj) {
      let { vid, device, channel, protocolType } = obj;
      if (!this.isChannelPlaying(channel)) {
        this.setChannelMap(channel, { vid, device, channel, protocolType });
      }

      this.emitPlayStart();
    },
    onPlayEnd(obj) {
      let { device, channel, begintime, endtime } = obj;
      this.setChannelMap(channel);

      this.emitPlayEnd();
    },
    onRegister() {

    },
    onPlayerMessage(obj) {
      const { status, info } = obj;
      if ([19].includes(status)) {
        this.$message.warning(info || '高级别用户播放视频通知');
      }
    },
    async onFtpDown(data) {
      const result = await this.ftpDownloadToServer(data);
      this.$message.info(result.info);
      if (result.status === 1) {
        return result.serial;
      }
      return '';
    },
    async onFtpCancel(data) {
      const result = await this.ftpCancel(data);
      this.$message.info(result.info);
    },
    emitPlayStart() {
      this.$emit('playStart', this.plateObj);
    },
    emitPlayEnd() {
      this.$emit('playEnd', this.plateObj);
    }
  }
}
</script>

<style lang="scss" scoped>
.VodPlayer {
}
.playerContainer {
  flex: 1;
  height: 100%;
  width: 100%;
  display: block;
}
.PlaybackBottom {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>