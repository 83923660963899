<template>
  <div class="VodSide">
    <GroupVehicleTree
      ref="gvTree"
      class="TreeOuter"
      :defaultExpandAll="true"
      :showSearch="true"
      showVehicle
      :onlyVideo="false"
      inputStr="设备名称/终端"
      :updateVehicleTime="15"
      :renderIcon="renderIcon"
      :visibleFilter="onTreeFilter"
      @node-click="onNodeClick"
      @searchSelect="onNodeClick"
    >
      <template v-slot="{ data }">
        <div class="NodeLabel" :title="getNodeLabel(data)">
          {{ getNodeLabel(data) }}
          <template v-if="data.nodeType === 2">
            <!-- 视频设备 -->
            <Iconfont
              v-if="data.isVideo"
              :name="data.ACC === 0 ? 'icon-ACCkai' : 'icon-ACCguan'"
            ></Iconfont>
            <!-- 部标设备 -->
            <Iconfont
              v-else
              :name="data.ACC === 0 ? 'icon-kai' : 'icon-guan'"
            ></Iconfont>

            <div class="stateSpan">
              <div :class="[colorData[data.Z] || 'NeverOnline']">
                {{ getStateLabel(data.Z) }}
              </div>
            </div>
          </template>
        </div>
      </template>
    </GroupVehicleTree>

    <div class="SearchBox">
      <!-- 开始时间 -->
      <div
        v-loading="searchLoading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255,255,255,0.5)"
      >
        <div class="row">
          <div class="rowLabel">开始时间:</div>
          <div class="rowContent">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              class="w100"
              v-model="queryDate.beginTime"
              type="datetime"
              placeholder="选择时间"
              :editable="false"
              :clearable="false"
              @change="changeTime(startM.message, startM.times, 'beginTime')"
            ></el-date-picker>
          </div>
        </div>
        <!-- 结束时间 -->
        <div class="row">
          <div class="rowLabel">结束时间:</div>
          <div class="rowContent">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              class="w100"
              v-model="queryDate.endTime"
              type="datetime"
              placeholder="选择时间"
              :editable="false"
              :clearable="false"
              @change="changeTime(endM.message, endM.times, 'endTime')"
            ></el-date-picker>
          </div>
        </div>
        <!-- 文件位置 -->
        <div class="row">
          <div class="rowLabel">存储位置:</div>
          <div class="rowContent">
            <el-select class="w100" v-model="storgetype">
              <el-option
                v-for="item in storgetypeOptions"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- NOTE: 暂时查所有类型 -->
      <!-- 文件类型 -->
      <!-- <div class="row">
        <div class="rowLabel">录像类型:</div>
        <div class="rowContent">
          <el-select class="w100" v-model="videoType">
            <el-option
              v-for="item in videoTypeOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </div>
      </div>-->
      <div class="row">
        <el-button
          class="queryBtn"
          :loading="searchLoading"
          icon="el-icon-search"
          type="primary"
          @click="onQueryClick"
          >查 询</el-button
        >
        <el-button
          class="queryBtn"
          icon="el-icon-search"
          type="danger"
          @click="onCancel"
          >取 消</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import GroupVehicleTree from "@/components/VehicleGroupTree";
import dayjs from "dayjs";
import { sleep } from "@/utils/sleep";
import { mapState, mapMutations } from "vuex";

/**
 * 文件位置
 */
export const storgetypeOptions = [
  { value: 1, label: "终端设备" },
  { value: 240, label: "应急存储器" },
  // { value: -1, label: '本地磁盘' }
];

/**
 * 录像类型
 */
export const videoTypeOptions = [
  { value: 0, label: "全部录像" },
  { value: 1, label: "报警录像" },
];

export default {
  name: "VodSide",
  components: {
    GroupVehicleTree,
  },
  props: {
    // 搜索回调
    searchCallback: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      // searchLoading: searchLoading,
      storgetypeOptions,
      videoTypeOptions,
      // 录像类型
      videoType: 0,
      // 文件位置
      storgetype: 1,
      // 报警类型
      alarmSign: 0,
      // 查询时间
      // queryDate: dayjs().format("YYYY-MM-DD 23:59:59"),
      queryDate: {
        beginTime: dayjs().format("YYYY-MM-DD 00:00:00"),
        endTime: dayjs().format("YYYY-MM-DD 23:59:59"),
      },
      currentVehicle: null,
      startM: {
        message: "开始时间不能大于结束时间",
        times: "00:00:00",
      },
      endM: {
        message: "结束时间不能小于开始时间",
        times: "23:59:59",
      },
      isRequest: false,
      colorData: {
        0: "yellow",
        1: 'Online',
        // 1: "NeverOnline",
        2: "Online",
        3: "blue",
        4: "Offline",
        5: "NeverOnline",
      },
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapMutations(["setLoading"]),
    // 获取车辆状态标签
    getStateLabel(state) {
      // if (state === 0) return "离线";
      // if (state === 1) return "行驶";
      // if (state === 2) return "停车";
      // if (state === 3) return "从未上线";
      // if (state === 4) return "过期";

      // if (state === 0) return '怠速';
      // if (state === 1) return '行走';
      // if (state === 2) return '作业';
      // if (state === 3) return '通电';
      // if (state === 4) return '熄火';
      // if (state === 5) return '离线';
      // if (state === 6) return '从未上线';

      // if (state === 0) return "怠速";
      // if (state === 1) return "行驶";
      // if (state === 2) return "作业";
      // if (state === 3) return "通电";
      // if (state === 4) return "熄火";
      // if (state === 5) return "离线";


      if (state === 0) return "停工";
      if (state === 1) return "开工";
      if (state === 5) return "离线";
      return "从未上线";
    },
    onTreeFilter(data) {
      // 放开不显示离线车辆-对应搜索框
      if (data.nodeType === 2) {
        // if (this.userInfo.name == '中国石油') {
        //   return true;
        // }

        if (
          data.isSupportMedia &&
          ((data.Z < 5))
        ) {
          return true;
        } else {
          return false;
        }
        // return [0,2,3].includes(data.Z);
      }
      return true;
    },
    getNodeLabel(data) {
      // 车组
      if (data.nodeType === 1) return data.groupName;
      // 车辆
      if (data.nodeType === 2) return data.P;
      // 通道号
      if (data.nodeType === 2) return data.label;
    },
    renderIcon(data, node) {
      const iconProps = {};
      if (data.nodeType === 2) {
        // 自定义车辆图标
        const { Z } = data;
        // 离线
        iconProps.name = "icon-lixian";
        if (Z === 0) {
          iconProps.name = "icon-baojing";
        }
        if (Z === 1) {
          iconProps.name = "icon-baojing";
        } else if (Z === 2) {
          iconProps.name = "icon-hangshi1";
        } else if (Z === 3) {
          iconProps.name = "icon-tingche1";
        } else if (Z === 4) {
          iconProps.name = "icon-baojing";
        }
      }

      return iconProps;
    },
    channelStr2Channels(channelStr) {
      try {
        return JSON.parse(channelStr);
      } catch (err) {
        //
      }
      return [];
    },
    async search(vehicle, awaitTime = 0) {
      const { storgetype, videoType, alarmSign, queryDate, searchCallback } =
        this;
      const {
        isVideo,
        // 车辆行驶状态 0: 离线; 1: 行驶; 2: 停车; 3: 从未上线; 4: 过期
        Z: onlineState,
        // ACC状态 0: ACC开; 1: ACC关
        ACC,
        // 设备名称
        P: plate,
        // 车辆Id
        V: vehicleId,
        // 所属车组Id
        M: groupId,
        // 所属车组名称
        groupName,
        // 终端号
        // N: device,
        mediaTerminalNo: device,
        // 终端类型 'GF-1078','GB-1078', 'GA-系列'
        T: terminalType,
        // 通道号
        camreaLine: channelStr,
        // 6: 黑标; 其他不处理
        percentageOfFlow,
        mediaOnline, // 视频OVP是否在线
      } = vehicle;
      console.log(onlineState,'-----------------------');
      if (![0, 1].includes(onlineState)) {
        return this.$message.warning("该车辆未在线(行驶或停车)");
      }
      //  || (![0, 2, 3, 4].includes(onlineState) && this.isZgsy)
      
      if (!isVideo || !mediaOnline) {
        return this.$message.warning("该车辆非视频设备/OVP未在线");
      }
      // if (mediaOnline) {
      //   return this.$message.warning("该车辆ACC已关闭");
      // }

      const channels = this.channelStr2Channels(channelStr);

      if (!channels.length) {
        return this.$message.warning("该车辆没有设置通道号");
      }

      // 开始时间 时间戳 秒
      const beginTime = dayjs(
        dayjs(queryDate.beginTime).format("YYYY-MM-DD HH:mm:ss")
      ).unix();
      // 结束时间 时间戳 秒
      const endTime = dayjs(
        dayjs(queryDate.endTime).format("YYYY-MM-DD HH:mm:ss")
      ).unix();
      // 特殊协议: 1: 粤标
      const SpecialSign = percentageOfFlow === 6 ? 1 : 0;

      // this.searchLoading = true;

      this.isRequest = true;
      this.$nextTick(() => {
        this.setLoading(true);
      });

      try {
        if (awaitTime) {
          await sleep(awaitTime);
        }

        if (searchCallback) {
          await searchCallback({
            vehicleId,
            groupId,
            plate,
            groupName,
            device,
            terminalType,
            SpecialSign,
            beginTime,
            endTime,
            channels,
            storgetype,
            // TODO: 报警类型暂时为`1`,
            alarmSign: videoType === 0 ? 0 : 1,
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.setLoading(false);
        this.isRequest = false;
        // this.searchLoading = false;
      }
    },
    onCancel() {
      if (this.isRequest) {
        this.$message.info("已经取消了查询");
        this.setLoading(false);
      } else {
        this.$message.info("没有可以取消的查询");
      }
      this.isRequest = false;
    },
    async onQueryClick() {
      const intervalDay = dayjs(this.queryDate.endTime).diff(
        this.queryDate.beginTime,
        "day"
      );
      if (intervalDay > 2) {
        return this.$message.warning("日期间隔不能大于3天");
      }
      let newCurrentVehicle;
      if (this.vehicleVideo) {
        newCurrentVehicle = this.vehicleVideo;
      }
      let { currentVehicle } = this;
      if (currentVehicle) {
        newCurrentVehicle = currentVehicle;
      }
      if (!newCurrentVehicle) {
        return this.$message.warning("请选择车辆");
      }
      this.search(newCurrentVehicle);
    },
    onNodeClick(data) {
      const { gvTree } = this.$refs;
      if (!gvTree) return;
      if (data.nodeType !== 2) {
        this.currentVehicle = null;
        return;
      }

      const item = gvTree.findNodeData(data.key);

      this.currentVehicle = { ...item };
    },
    // 时间限制
    changeTime(message, times, time) {
      const { beginTime, endTime } = this.queryDate;
      if (endTime && endTime < beginTime) {
        this.$message.warning(`${message}`);
        this.queryDate[time] = dayjs().format(`YYYY-MM-DD ${times}`);
      }
    },
  },
  computed: {
    ...mapState(["searchLoading", "vehicleVideo", "userInfo"]),
    // 中国石油判断
    isZgsy() {
      return (
        this.$store.state.userRouter?.children?.find(
          (r) => r.code === "zgsy"
        ) && this.$store.state.userInfo.name != "广东一立科技"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.VodSide {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.TreeOuter {
  flex: 1;
  overflow: hidden;
}
.SearchBox {
  margin-top: 10px;
  height: 200px;
  background-color: #fff;
  padding: 20px 10px;
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.rowLabel {
  text-align: end;
  padding-right: 7px;
}
.rowContent {
  flex: 1;
}
.queryBtn {
  // margin: 0 10px;
  flex: 1;
}
.NodeLabel {
  min-width: 100px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.w100 {
  width: 100%;
}
.stateSpan {
  width: auto;
  font-size: 12px;
  display: inline-block;
  margin-left: 15px;
}
// .Online {
//   color: #25b146;
//   height: 18px;
//   line-height: 18px;
//   background: #dcffe1;
//   border: 1px solid #3cd831;
//   border-radius: 2px;
//   text-align: center;
//   padding: 0 3px;
// }
// .Offline {
//   color: #eb4827;
//   height: 18px;
//   line-height: 18px;
//   background: #fcece8;
//   border: 1px solid #ff7e6a;
//   border-radius: 2px;
//   text-align: center;
//   padding: 0 3px;
// }
</style>
